.tab-box a:hover .img-icon,
.tab-active img {
  filter: brightness(0) invert(100%);
}
.custom-file-type {
  position: relative;
  width: 166px;
  height: 40px;
  overflow: hidden;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-file-type input {
  opacity: 0;
  z-index: 999;
  position: relative;
}
.custom-file-type label {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-align: center;
  background: #ff9354;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}
.main-tab-box {
  height: calc(100vh - 64px);
}
