.form-grid {
  display: grid;
  grid-template-columns: auto 55%;
  gap: 50px;
  height: 100vh;
  width: 100%;
}
.left-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px 40px 40px 40px;
}
.signin-form {
  max-width: 325px;
  margin: auto;
  width: 100%;
}
.signin-form form {
  gap: 16px;
  font-size: 14px;
}
.signin-form h3 {
  font-size: 22px;
  font-weight: 800;
  color: #505050;
  margin-bottom: 25px;
}
.signin-form input {
  height: 48px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #d0d0d0 !important;
  box-shadow: none !important;
  outline: none !important;
  background: #fff !important;
}

.signin-form button {
  height: 46px;
  border-radius: 8px;
}
.signin-form a {
  color: #ff9354;
  text-decoration: none;
}

.signin-btn {
  background: #ff9354 !important;
  height: 46px;
  color: #fff !important;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}
.not-account {
  color: #828282;
}
.or-text span {
  background: #fff;
  display: inline-block;
  padding: 10px 13px;
  width: -moz-fit-content;
  width: fit-content;
}
.or-text {
  margin-top: 8px;
  position: relative;
  margin-bottom: 8px;
}
.or-text::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #d0d0d0;
  left: 0;
  right: 0;
  top: 50%;
  z-index: -1;
  bottom: 0;
}

.not-account a {
  color: #828282 !important;
  font-weight: 600;
}
.signin-other {
  background: #fff !important;
  color: #505050 !important;
  font-weight: bold;
  box-shadow: none;
  outline: none;
  border-color: #d0d0d0;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  gap: 7px;
}
.signin-other svg {
  width: 20px;
  height: 20px;
}
.signin-other:hover {
  border-color: #ff9354 !important ;
}
.right-image {
  background-image: url(../../../images/decoration-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  position: relative;
  padding: 90px 45px 90px 60px;
}
.glass-card {
  background: #b7e9f626;
  height: 100%;
  border-radius: 10px;
  padding: 48px;
  color: #fff;
  height: 765px;
  border: 1px solid #fff;
  backdrop-filter: blur(23px);
}
.glass-card h2 {
  font-size: 45px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
}
.girl-img {
  position: absolute;
  bottom: 9.3%;
  right: 0;
}
@media screen and (min-width: 1440px) {
  .glass-card h2 {
    font-size: 55px;
  }
}
@media screen and (min-width: 1370px) {
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    height: 100vh;
    width: 100%;
  }
  .signin-form {
    max-width: 500px;
  }
}
@media screen and (max-width: 1440px) {
  .glass-card {
    max-width: 600px;
    margin: auto;
  }
  .glass-card {
    height: 800px;
  }
}
@media screen and (max-width: 1200px) {
  .form-grid {
    gap: 0px;
  }
}
