.icon-box {
  width: 36px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-box {
  width: 36px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
.active-icon-box,
.icon-box:hover {
  background: #ff9354;
}
.active-icon-box img,
.icon-box:hover img {
  filter: brightness(0) invert(100%);
}

.gradient-icon {
  filter: none !important;
}
